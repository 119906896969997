import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { createTheme, PaletteMode, Theme, ThemeProvider as MuiThemeProvider } from '@mui/material'

import { getDesignTokens, globalComponentOverwrite, WHITE_LABEL_THEME, whiteLabelThemeRecord } from './themes'

export type GlobalThemeProviderProps = {
  children: React.ReactNode
}

export const ColorModeContext = createContext({ toggleColorMode: () => { } })

export const useColorModeContext = () => {
  const context = useContext(ColorModeContext)

  if (context === undefined) {
    throw new Error(
      'useColorModeContext must be used within a GlobalThemeProvider',
    )
  }

  return context
}

type WhiteLabelThemeModuleType = typeof import('src/styles/OrcodaTheme')

export const GlobalThemeProvider: React.FC<GlobalThemeProviderProps> = ({ children }) => {
  const [mode, setMode] = useState<PaletteMode>('light')
  const [whiteLabelTheme, setWhiteLabelTheme] = useState<WhiteLabelThemeModuleType>()

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
      },
    }),
    [],
  )

  // Dynamic White Label Theme
  useEffect(() => {
    import(`./${whiteLabelThemeRecord[WHITE_LABEL_THEME ?? 'orcoda']}`).then((module) => {
      setWhiteLabelTheme(module.default)
    })
  }, [WHITE_LABEL_THEME])

  const theme: Theme | undefined = useMemo(() => {
    if (whiteLabelTheme) {
      const whiteLabelThemeOptions = whiteLabelTheme.getTheme(mode)
      const designTokens = getDesignTokens(whiteLabelThemeOptions)

      return createTheme(designTokens, {
        components: globalComponentOverwrite,
      })
    }
  }, [mode, whiteLabelTheme])

  return (
    <ColorModeContext.Provider value={colorMode}>
      {theme && (
        <>
          <link rel="stylesheet" href="fonts/fonts.css" />
          
          <MuiThemeProvider theme={theme}>
            {children}
          </MuiThemeProvider>
        </>
      )}
    </ColorModeContext.Provider>
  )
}

