/* eslint-disable import/no-named-as-default-member */
import 'allotment/dist/style.css'
import './styles/GlobalCSS.css'

import { AnimatePresence } from 'framer-motion'
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import PreloadImages from './components/PreloadImage/PreloadImage'
import { Layout } from './fragments/Layout/Layout'
import { useServiceWorker } from './hooks/useServiceWorker'
import initializeDayJs from './libs/dayjsConfig'
import { AuthPage } from './pages/AuthPage'
import { ChangePasswordPage } from './pages/ChangePasswordPage'
import { Dashboard } from './pages/Dashboard/Dashboard'
import { LoginPage } from './pages/LoginPage'
import { ManagementCustomers } from './pages/Management/Customers'
import { ManagementLocations } from './pages/Management/Locations'
import { Management } from './pages/Management/Management'
import { ManagementVehicles } from './pages/Management/Vehicles'
import { Operations } from './pages/Operation/Operation'
import { Reports } from './pages/Reports/Reports'
import { ReportsDownloads } from './pages/Reports/ReportsDownloads'
import { ResetPasswordPage } from './pages/ResetPasswordPage'
import { SettingsAccount } from './pages/Settings/Account'
import { SettingsAttributes } from './pages/Settings/Attributes'
import { SettingsContents } from './pages/Settings/Contents'
import { SettingsOrgUnits } from './pages/Settings/OrgUnits'
import { Settings } from './pages/Settings/Settings'
import { SettingsTeamMembers } from './pages/Settings/TeamMembers'
import { SettingsThirdParties } from './pages/Settings/ThirdParties'
import reportWebVitals from './reportWebVitals'
import { persistor, store, useAppDispatch, useAppSelector } from './store/store'
import { setupApp } from './store/thunks/setupApp'
import { GlobalThemeProvider } from './styles/GlobalThemeProvider'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)

const App: React.FC = () => {
  // Register service worker for PWA and push notifications
  useServiceWorker()
  initializeDayJs()

  return (
    <div style={{ overflowX: 'hidden' }}>

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalThemeProvider>
            <BrowserRouter future={{ v7_startTransition: true }}>
              <ConnectAppRoutes />
            </BrowserRouter>
          </GlobalThemeProvider>
        </PersistGate>
      </Provider>
    </div>
  )
}

const ConnectAppRoutes: React.FC = () => {
  const activeOrgUnit = useAppSelector(state => state.settings.activeOrgUnit)
  const isWaipaManager = useAppSelector(state => state.user.idtype === 'MANAGER' && state.user.ui_type === 'WAIPA')
  const dispatch = useAppDispatch()
  const location = useLocation()
  const imagesToPreload: string[] = [
    '/svgs/connect-locked.svg',
    '/svgs/connect-unlocked.svg',
    '/svgs/connect-green-map-icon.svg',
    '/svgs/connect-red-map-icon.svg',
    '/svgs/connect-yellow-map-icon.svg',
    '/svgs/connect-car-birds-eye.svg',
  ]

  useEffect(() => {
    if (activeOrgUnit) {
      // Setup application state everytime org unit changes
      dispatch(setupApp({
        ORG_UNIT_ID: activeOrgUnit.ORG_UNIT_ID || '',
      }))
    }
  }, [activeOrgUnit])

  return (
    <>
      <AnimatePresence mode={'wait'}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<LoginPage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/reset_password" element={<ResetPasswordPage />} />
          <Route path="/redirect/change_password/" element={<ChangePasswordPage />} />
          {isWaipaManager ? (
            <Route element={<Layout />} >
              <Route path="/dashboard/operations" element={<Operations />} />
              <Route path="*" element={<Navigate to="/dashboard/operations" replace />} />
            </Route>
          ) : (
            <Route element={<Layout />} >
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/operations" element={<Operations />} />
              <Route element={<Management />}>
                <Route path="/dashboard/management/" element={<Navigate to="/dashboard/management/customers" replace={true} />} />
                <Route path="/dashboard/management/customers" element={<ManagementCustomers />} />
                <Route path="/dashboard/management/locations" element={<ManagementLocations />} />
                <Route path="/dashboard/management/vehicles" element={<ManagementVehicles />} />
              </Route>
              <Route element={<Reports />}>
                <Route path="/dashboard/reports/" element={<Navigate to="/dashboard/reports/downloads" replace={true} />} />
                <Route path="/dashboard/reports/downloads" element={<ReportsDownloads />} />
              </Route>
              <Route element={<Settings />}>
                <Route path="/dashboard/settings/" element={<Navigate to="/dashboard/settings/org_units" replace={true} />} />
                <Route path="/dashboard/settings/org_units" element={<SettingsOrgUnits />} />
                <Route path="/dashboard/settings/team_members" element={<SettingsTeamMembers />} />
                <Route path="/dashboard/settings/contents" element={<SettingsContents />} />
                <Route path="/dashboard/settings/attributes" element={<SettingsAttributes />} />
                <Route path="/dashboard/settings/third_parties" element={<SettingsThirdParties />} />
                <Route path="/dashboard/settings/account" element={<SettingsAccount />} />
              </Route>
            </Route>
          )}
        </Routes>
      </AnimatePresence>
      <PreloadImages imageUrls={imagesToPreload} />
    </>
  )
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
