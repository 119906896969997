import React, { useCallback, useState } from 'react'
import { CarshareApiService } from 'src/apis/CarshareApiService'
import { Button } from 'src/components/Button/Button'
import { StyledDivider } from 'src/components/Form/StyledForm'
import { TextField } from 'src/components/Form/TextField'
import { TextFieldLabel } from 'src/components/Form/TextFieldLabel'
import { LoginCard } from 'src/components/LoginCard/LoginCard'
import { LoginRoute } from 'src/fragments/Route/LoginRoute'
import { RequestHandler, SuccessHandler, useApiRequest } from 'src/hooks/useApiRequest'
import { useAppDispatch } from 'src/store/store'
import { setupUser } from 'src/store/thunks/setupUser'
import { pxToRem } from 'src/styles/themes'
import { User } from 'src/types/User'

import { Alert, FormControl, Link, styled, Typography } from '@mui/material'

export type LoginPageProps = {
  //
}

const getAuthEndpoint = () => {
  const host = process.env.REACT_APP_FN_AUTH_API_HOST || ''

  return `${host}/api/auth/provider/`
}

export const LoginPage: React.FC<LoginPageProps> = () => {
  const [orgName, setOrgName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [mfaCode, setMfaCode] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showMFA, setShowMFA] = useState(false)
  const { loading, errorMessage, setErrorMessage, setLoading, request } = useApiRequest()
  const dispatch = useAppDispatch()

  const checkAuthProvider = async () => {
    try {
      setErrorMessage(null)
      setLoading(true)

      const formData = new FormData()

      formData.append('provisional_id', 'REQUEST')
      formData.append('auth_service', process.env.REACT_APP_FN_AUTH_SERVICE || '')
      formData.append('auth_org_name', orgName || 'NONE')
      formData.append('username', email)
      formData.append('login', 'Login')
      formData.append('wab_csrf_token', process.env.REACT_APP_CARSHARE_API_KEY || '')
      formData.append('wab_requesting_url', window.location.href.split('?')[0] || '')

      const response = await fetch(getAuthEndpoint(), {
        method: 'POST',
        body: formData,
      })

      const data = await response.json()

      if (data.status === 'ERROR') {
        setErrorMessage(data.error)

        return
      }

      if (data.status === 'OK' && data.results.length > 0) {
        const auth = data.results[0]

        if (['PASSWORD', 'PASSWORD-MFA'].includes(auth.auth_action)) {
          setShowPassword(true)
        } else if (auth.auth_action === 'MFA') {
          setShowMFA(true)
        } else if (auth.auth_action === 'SSO' && auth.auth_login_url) {
          window.location.href = `${auth.auth_login_url}&state=${auth.provisional_id}`
        }
      }
    } catch (err) {
      setErrorMessage('Failed to check authentication provider')
    } finally {
      setLoading(false)
    }
  }

  const handleLogin = useCallback(async () => {
    const loginHandler: RequestHandler<User> = () => (
      CarshareApiService.post('login', {
        username: email,
        password: password,
        auth_org_name: orgName,
      })
    )

    const onSuccess: SuccessHandler<User> = (users) => {
      if (users.length > 0 && users[0].idtype === 'CUSTOMER') {
        throw new Error('Invalid user account. Please use the customer app to login.')
      }

      dispatch(setupUser(users[0]))
    }

    await request(loginHandler, onSuccess)
  }, [email, password, dispatch, request])

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!showPassword && !showMFA) {
      await checkAuthProvider()
    } else {
      await handleLogin()
    }
  }

  return (
    <LoginRoute>
      <StyledLoginPage>
        <LoginCard>
          {errorMessage && (
            <StyledAlert severity="error" icon={false}>
              {errorMessage}
            </StyledAlert>
          )}

          <StyledForm onSubmit={handleSubmit}>
            <FormControl error={!!errorMessage}>
              <TextFieldLabel htmlFor="org">
                Organisation *
              </TextFieldLabel>
              <TextField
                id="org"
                type="text"
                autoFocus
                autoComplete="organization"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                disabled={loading || showPassword || showMFA}
                fullWidth
              />
            </FormControl>

            <FormControl required error={!!errorMessage} sx={{ marginTop: pxToRem(16) }}>
              <TextFieldLabel htmlFor="email">
                Email
              </TextFieldLabel>
              <TextField
                id="email"
                type="text"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading || showPassword || showMFA}
                fullWidth
              />
            </FormControl>

            {showPassword && (
              <FormControl required error={!!errorMessage}>
                <StyledTextFieldLabel htmlFor="password">
                  Password
                </StyledTextFieldLabel>
                <TextField
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  disabled={loading}
                  fullWidth
                />
              </FormControl>
            )}

            {showMFA && (
              <FormControl required error={!!errorMessage}>
                <StyledTextFieldLabel htmlFor="mfa">
                  MFA Code
                </StyledTextFieldLabel>
                <TextField
                  id="mfa"
                  type="text"
                  value={mfaCode}
                  onChange={(e) => setMfaCode(e.target.value)}
                  disabled={loading}
                  fullWidth
                />
              </FormControl>
            )}

            {showPassword && (
              <StyledLink href="/reset_password">
                Forgot your password?
              </StyledLink>
            )}

            <StyledSubmitButton
              type="submit"
              primary
              fullWidth
              disabled={loading}
            >
              {!showPassword && !showMFA ? 'Continue' : 'Login'}
            </StyledSubmitButton>
          </StyledForm>

          {process.env.REACT_APP_ANDROID_APP_URL && (
            <>
              <StyledDivider sx={{ marginTop: pxToRem(24), marginBottom: pxToRem(16) }}>
                <span>
                  <Typography variant="body1">
                    OR
                  </Typography>
                </span>
              </StyledDivider>
              <TextFieldLabel sx={{ width: '100%', textAlign: 'start' }}>
                Download Customer App
              </TextFieldLabel>
              <Button
                fullWidth
                outlined
                startIcon={
                  <img src="/svgs/android.svg" width={36} height={36} alt="Android logo" />
                }
                href={process.env.REACT_APP_ANDROID_APP_URL}
              >
                Download Android App
              </Button>
            </>
          )}
        </LoginCard>
      </StyledLoginPage>
    </LoginRoute>
  )
}


export const StyledLoginPage = styled('main')`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.background.default};

  ${props => props.theme.breakpoints.up('sm')} {
    height: 100vh;
  }
`

export const StyledForm = styled('form')`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledLink = styled(Link)`
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.pxToRem(14)};
  margin-top: ${props => props.theme.typography.pxToRem(12)};
  text-align: right;
  
  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(4)};
  }
`

export const StyledSubmitButton = styled(Button)`
  margin-top: ${props => props.theme.typography.pxToRem(16)};

  ${props => props.theme.breakpoints.up('md')} {
    margin-top: ${props => props.theme.typography.pxToRem(28)};
  }
`

export const StyledAlert = styled(Alert)`
  align-self: stretch;
  margin-bottom: ${props => props.theme.typography.pxToRem(16)};
`

export const StyledTextFieldLabel = styled(TextFieldLabel)`
  margin-top: ${props => props.theme.typography.pxToRem(16)};
`
