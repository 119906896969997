export const utf8ToBase64 = (str: string): string => {
  const encoder = new TextEncoder()
  const bytes = encoder.encode(str)

  return btoa(String.fromCharCode.apply(null, Array.from(bytes)))
}

export const base64ToUtf8 = (base64: string): string => {
  const binaryStr = atob(base64)
  const bytes = new Uint8Array(binaryStr.length)

  for (let i = 0; i < binaryStr.length; i++) {
    bytes[i] = binaryStr.charCodeAt(i)
  }

  const decoder = new TextDecoder()

  return decoder.decode(bytes)
}