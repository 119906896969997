import { ComponentProps } from 'react'

import { styled, TextField as MuiTextField } from '@mui/material'

export type TextFieldProps = Omit<ComponentProps<typeof MuiTextField>, 'variant' | 'round'> & {
  round?: boolean
}

export const TextField: React.FC<TextFieldProps> = ({ round, ...props }) => (
  <StyledOutlineTextField
    variant="outlined"
    $round={round}
    {...props}
  />
)

const StyledOutlineTextField = styled(MuiTextField, {
  shouldForwardProp: (prop) => prop !== '$round',
}) <{ $round: boolean | undefined }>`
  width: 100%;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: ${(props) => props.$round ? props.theme.typography.pxToRem(1000) : props.theme.typography.pxToRem(8)};
  
  .MuiOutlinedInput-root {
    height: ${(props) => props.theme.typography.pxToRem(48)};
    border-radius: ${(props) => props.$round ? props.theme.typography.pxToRem(1000) : props.theme.typography.pxToRem(8)};

    padding: 0;
  }

  .Mui-focused fieldset {
    border-color: ${(props) => props.theme.palette.text.primary};
    box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.paper}, 0 0 0 4px ${(props) => props.theme.palette.custom.button.focusedOutline};
  }

  .Mui-error.Mui-focused fieldset {
      box-shadow: 0 0 0 2px ${(props) => props.theme.palette.background.paper}, 0 0 0 4px rgba(211, 47, 47, 0.4);    
  }
  
  .Mui-disabled {
    .MuiSvgIcon-root {
      color: ${(props) => props.theme.palette.text.disabled};
    }
  }

  input {
    padding: ${(props) => props.theme.typography.pxToRem(12.5)} ${(props) => props.theme.typography.pxToRem(12)};
  }

  .MuiInputBase-multiline {
    height: unset;

    textarea {
      padding: ${(props) => props.theme.typography.pxToRem(12.5)} ${(props) => props.theme.typography.pxToRem(12)};
    }
  }

  .MuiFormHelperText-root {
    margin-left: 0;
  }
`
