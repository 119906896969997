import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'src/store/store'
import { setupUser } from 'src/store/thunks/setupUser'

import { Alert, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

const getAuthEndpoint = () => {
  const host = process.env.REACT_APP_FN_AUTH_API_HOST || ''

  return `${host}/api/auth/oauth/login/`
}

export type AuthPageProps = {
  onLogin?: (userData: Record<string, unknown>) => void
}

export const AuthPage: React.FC<AuthPageProps> = ({ onLogin }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const handleAuth = async () => {
      try {
        // Parse URL search params
        const searchParams = new URLSearchParams(location.search)
        const state = searchParams.get('state')
        const sessionState = searchParams.get('session_state')
        const code = searchParams.get('code')

        if (!state || !sessionState || !code) {
          throw new Error('Missing required authentication parameters')
        }

        // Create form data
        const formData = new FormData()

        formData.append('state', state)
        formData.append('session_state', sessionState)
        formData.append('code', code)
        formData.append('wab_csrf_token', process.env.REACT_APP_CARSHARE_API_KEY || '')
        formData.append('wab_requesting_url', window.location.href.split('?')[0] || '')

        // Make authentication request
        const response = await fetch(getAuthEndpoint(), {
          method: 'POST',
          body: formData,
        })

        const data = await response.json()

        if (data.status === 'ERROR') {
          throw new Error(data.error || 'Authentication failed')
        }

        // Handle successful authentication
        if (data.status === 'OK' && data.results.length > 0) {
          const users = data.results

          if (users.length > 0 && users[0].idtype === 'CUSTOMER') {
            throw new Error('Invalid user account. Please use the customer app to login.')
          }

          dispatch(setupUser(users[0]))
    
          if (onLogin) {
            onLogin(users[0])
          }

          navigate('/dashboard')
        } else {
          throw new Error('Invalid authentication response')
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Authentication failed')
      } finally {
        setLoading(false)
      }
    }

    handleAuth()
  }, [location, navigate, onLogin])

  if (loading) {
    return (
      <StyledAuthPage>
        <CircularProgress />
      </StyledAuthPage>
    )
  }

  if (error) {
    return (
      <StyledAuthPage>
        <StyledAlert severity="error" icon={false}>
          {error}
        </StyledAlert>
      </StyledAuthPage>
    )
  }

  return null
}

const StyledAuthPage = styled('main')`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.background.default};
`

const StyledAlert = styled(Alert)`
  max-width: 400px;
  width: 100%;
  margin: ${props => props.theme.spacing(2)};
`